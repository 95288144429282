<template>
  <div class="popup_wrap" style="width:800px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title"></h1>
      <br>
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="50%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CART010G010.069') }}<!-- 주의사항 --></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text_left valign_top">
                <div class="p10">
                  <!-- 주의사항 - 설명 -->
                  {{ $t('approval.033') }} <br><br>
                  {{ $t('approval.034') }} <br>
                  {{ $t('approval.035') }} <br>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="mt10" style="margin-left: 630px !important;">
          <input type="checkbox" id="agreea1" name="agree" v-model="agreeYn" true-value="Y" false-value="N">
          <label for="agreea1" class="color_black" v-html="$t('approval.036')">
          </label>
        </p>
      </div>

      <div class="text_center mt10">
        <a href="#" class="button blue lg" @click.prevent="goInfo()">{{ $t('msg.ONEX060P011.007') }}<!-- 확인 --></a>
        <a href="#" class="button gray lg" @click.prevent="$emit('close')">{{ $t('msg.ONEX060P011.008') }}<!-- 취소 --></a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import CalcIssueLimit from '@/pages/settle/CalcIssueLimit'
import rfDg from '@/api/rest/trans/rfDg'

export default {
  name: 'RfDataNotiPop',
  components: {
    CalcIssueLimit
  },
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          page: '',
          params: {}
        }
      }
    }
  },
  data () {
    return {
      agreeYn: 'N',
      lang: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.lang = this.auth.serviceLang
  },
  methods: {
    async goInfo () {
      if (this.agreeYn !== 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK335') }) // 약관에 동의 하여야 합니다.
        return
      } else {
        // 프로필 단위로 RF 동의내역 저장
        await rfDg.updateRfYn(this.auth.userId)
            .then(res => {
                console.log(res)
            })
      }

      this.$emit('callback', this.$props.parentInfo)
    }
  }
}
</script>
