var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }),
        _c("br"),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CART010G010.069")))]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "text_left valign_top" }, [
                  _c("div", { staticClass: "p10" }, [
                    _vm._v(" " + _vm._s(_vm.$t("approval.033")) + " "),
                    _c("br"),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("approval.034")) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("approval.035")) + " "),
                    _c("br"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "p",
            {
              staticClass: "mt10",
              staticStyle: { "margin-left": "630px !important" },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.agreeYn,
                    expression: "agreeYn",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  id: "agreea1",
                  name: "agree",
                  "true-value": "Y",
                  "false-value": "N",
                },
                domProps: {
                  checked: Array.isArray(_vm.agreeYn)
                    ? _vm._i(_vm.agreeYn, null) > -1
                    : _vm._q(_vm.agreeYn, "Y"),
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.agreeYn,
                      $$el = $event.target,
                      $$c = $$el.checked ? "Y" : "N"
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.agreeYn = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.agreeYn = $$c
                    }
                  },
                },
              }),
              _c("label", {
                staticClass: "color_black",
                attrs: { for: "agreea1" },
                domProps: { innerHTML: _vm._s(_vm.$t("approval.036")) },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "text_center mt10" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goInfo()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.007")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX060P011.008")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "50%" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }